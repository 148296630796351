@use 'scss/variables' as *;

.sidebar-panel {
  // position: fixed;
  // top: 0;
  // left: 0;
  min-height: 100vh;
  // z-index: 10001;
  width: $sidebar-width;
  // box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  -webkit-transition: all $sidebarTrnTime $transitionTiming;
  transition: all $sidebarTrnTime $transitionTiming;
  .navigation-hold {
    position: absolute;
    height: 100%;
    width: 100%;
    // padding-top: 64px;
    background: rgba(255, 255, 255, .95);
    left: 0;
    -webkit-transition: all $sidebarTrnTime $transitionTiming;
    transition: all $sidebarTrnTime $transitionTiming;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.footer {
  position: absolute !important;
  width: 100%;
  bottom: 0;
  z-index: 1;
}
.step-container {
  position: relative;
  min-height: 85%;

  .step-forms {
    min-height: 500px;
  }

  .questions-container {
    min-height: 100px;
    padding-top: 20px;

    h2.section-title {
      margin-top: 0;
      padding-left: 15px;
      color: var(--primary-text-color);
    }
  }
  .below-content {
    position:absolute;
    bottom: -125px;
    right:0;
    left:0;
  }
}

.loader-container {
  position: absolute;
  top: 53%;
}

.sidebar-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all $sidebarTrnTime $transitionTiming;
  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

.sidebar-compact,
.sidebar-compact-big {
  .app-user,
  .app-logo-text {
    display: none
  }
}

@media screen and (max-width: 599px) {
  .step-container {
    .below-content {
      position:relative;
      bottom: 0;
      right:0;
      left:0;
    }
  }
}
