@use 'sass:map';
@use 'mixins/ppal-theming' as *;
@use '@angular/material' as mat;

@include apply-portal-themes(false) {
  // $background: map.get($theme, background);
  $is-dark-theme: map.get($theme, is-dark);
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $foreground: map.get($theme, foreground);
  // $gradient-primary: map.get($background, hover);
  // $gradient-secondary: map.get($background, card);

  $underline-color-hover: mat.m2-get-color-from-palette($foreground, divider, if($is-dark-theme, 1, 0.87));
  // $underline-color-accent: mat.m2-get-color-from-palette($accent, text);
  $underline-color-warn: mat.m2-get-color-from-palette($warn, text);
  // $underline-focused-color: mat.m2-get-color-from-palette($primary, text);


  $fill-background: mat.m2-get-color-from-palette($foreground, base, if($is-dark-theme, 0.1, 0.04));
  $underline-color: mat.m2-get-color-from-palette($foreground, divider, if($is-dark-theme, 0.5, 0.42));
  // $underline-color-bas
  $underline-focused-color: mat.m2-get-color-from-palette($primary, text);
  $label-color: mat.m2-get-color-from-palette($foreground, secondary-text, if($is-dark-theme, 0.7, 0.6));

  .StripeElement {
    background-color: $fill-background;
    border-color: $underline-color;
    color: $label-color;
  }

  .StripeElement:hover {
    border-color: $underline-color-hover;
  }

  .StripeElement--focus {
    border-color: $underline-focused-color !important;
  }

  .StripeElement--invalid {
    color: $underline-color-warn;
    border-color: $underline-color-warn !important;
  }
}

.StripeElement {
  margin: 0 0 1.34375em;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-radius: 4px 4px 0 0;
  padding: 1em .75em 1em .75em;
  transition: padding-bottom 150ms ease;
  transition: border-width 150ms ease;
  transition: border-color 150ms ease;
}

.StripeElement:hover,
.StripeElement--focus,
.StripeElement--invalid {
  padding-bottom: calc(1em - 1px);
  border-bottom-width: 2px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
