@use 'sass:map';
// Third party style files
// Sidebar Scroll
@use '@angular/material' as mat;
@forward 'perfect-scrollbar/css/perfect-scrollbar.css';

// Main Variables File
@use "scss/variables" as *;
@forward "scss/themes";
@forward 'scss/material-overrides';
@forward "scss/main/layout";
@forward "scss/main/pages";
@forward "scss/main/scaffolding";
@forward "scss/main/typography";
@forward "scss/main/font-sizes";
@forward "scss/main/quill";
@forward "scss/main/googlemaps";
@forward "scss/main/action-bar";
@forward "scss/main/stripe";

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intake-stepper {
  $number-size: 24px;
  padding: 0 20px;
  .intake-stepper-step {
    .intake-stepper-step-header {
      display: flex;
      align-items: center;
      padding: 24px;
      overflow: hidden;
      max-height: 24px;
      box-sizing: content-box;
      &:hover:not(.disabled) {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.04);
      }
      .intake-stepper-step-label {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .intake-stepper-step-number {
        background-color: rgba(0, 0, 0, 0.30);
        color: #fff;
        border-radius: 50%;
        height: $number-size;
        width: $number-size;
        min-width: $number-size;
        margin-right: 12px;
        padding-top: 1px;
        justify-content: center;
        display: flex;
      }
      &.active {
        .intake-stepper-step-number {
          background-color: var(--primary-background);
        }
      }
    }
    .intake-stepper-step-line {
      margin-left: 36px;
      border: 0;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: -16px;
        bottom: -16px;
        left: 0;
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: rgba(0, 0, 0, 0.12);
      }
    }
  }
}

.home-section {
  padding: 40px 0;
}
.home-section mat-card:hover {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
}
.section-header {
  margin: 0 0 2rem;
}
.section-header h2 {
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 16px;
}
.section-header p {
  font-size: 16px;
  max-width: 36rem;
  margin: 0;
}
[mat-card-icon] {
  font-size: 64px !important;
  height: 64px !important;
  width: 64px !important;
  transition: all .3s ease;
}
.home-fancy-card {
  cursor: pointer;
  border-top: 2px solid #212121;
  transition: all .3s ease;
  text-align: center;

  .description {
    text-align: left;
  }

  h5 {
    margin: 0;
  }

  &.active, &:hover {
    border-top: 2px solid #58B7A4;
    [mat-card-icon] {
      color: #58B7A4;
    }
  }
}

.hidden {
  display: none !important;
}

// content {
//   overflow-y: scroll;
// }
.section-of-questions {
  @media screen and (max-width: 599px) {
    margin-top: 15px;
    padding-top: 0px;
  }
  .multiple-answers {
    margin-bottom: 0;
    white-space: pre-wrap;
  }
  .intro-label {
    font-size: 120%;
    margin: 0;
    padding-bottom: 14px;
    &.emphasize {
      font-weight: 600;
      font-size: 150%;
      color: #ff0000;
    }
    @media screen and (max-width: 599px) {
      padding-top:0px;
    }
  }
}

.section-of-questions hr,
hr.card-break {
  width: calc(100% + 50px);
  margin-left: -25px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-color: rgba(0, 0, 0, .2);
  border-top-width: 0;
  border-bottom-width: 1px;
}


.form-question {
  .question {
    .if-question {
      .if-question-label {
        display: inline;
        margin: 0;
        font-size: 120%;
        @media screen and (max-width: 599px) {
          padding-top:0px;
        }
      }
      .if-question-radios {
        padding: 14px 0 5px;
        .if-question-bool {
          display: inline-block;
          margin-right: 15px;
        }
      }
    }
    .mat-mdc-form-field {
      width: 100%;
      font-size: 16px;
    }
    .mat-mdc-selection-list {
      .mat-mdc-list-option {
        height: auto;
      }
    }
    .radio-question {
      .mat-mdc-radio-button {
        margin-right: 15px
      }
    }
  }
  .if-questions {
    padding-top: 9px;
    .if-questions-intro {
      margin-top: 24px;
    }
  }
}

.terms-list {
  &,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    margin-left: 1em;
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
    a, .title {
      font-weight: 500;
    }
    >.mat-mdc-form-field-error {
      display: block;
      padding-top: 5px;
    }
    >.terms {
      max-height: 150px;
      padding: 10px;
      margin-bottom: 1em;
      margin-left: -1em;
      overflow: scroll;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 10px;
      h3 {
        font-size: 1.3em;
        margin: 0;
        margin-bottom: 0.5em;
        font-weight: bold;
      }
    }
    >.mat-mdc-checkbox {
      margin-left: -1em;
    }
  }
}

.terms {
  a {
    color: var(--primary-text-color);
  }
}
.text-dialog {
  section {
    header {
      line-height: 35px;
      font-size: 1.2em;
      .bigGreenCheckmark {
        background-color: map.get($mat-primary, 500);
        border-radius: 50%;
        color: #fff;
        padding: 5px;
      }
      >span {
        padding-left: 8px;
      }
    }
    content {
      display: block;
      padding: 15px;
    }
  }
}

.small-checkbox {
  margin-right: 10px;
}

.thankyou-row {
  margin-top: 4em;
  .thankyou-section {
    text-align: center;
    max-width: 400px !important;
    p {
      font-size: 24px;
      color: map.get(mat.$green-palette, 750);
      font-weight: 700;
      margin: 20px 0 5px;
    }
    hr {
      max-width: 40px;
      height: 0px;
      border-top: 1px solid var(--divider);
      margin: 5px auto 10px;
      width: 100%;
    }
    small {
      font-size: 14px;
      color: var(--primary-darker-color);
      max-width: 230px;
      margin: 0 auto;
    }
  }
  .credit-card {
    margin-bottom: 30px
  }
}

/* Shared */

.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  padding: 0 15px 0 46px;
  border: none;
  line-height: 36px;
  white-space: nowrap;
  border-radius: 2px;
  font-size: 16px;
  color: #FFF;
  cursor: pointer;
}

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */

.loginBtn--facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  text-shadow: 0 -1px 0 #354C8C;
  @media screen and (max-width: 599px) {
    width: 100%;
  }
}

.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

// next button fixed for mobile view in patient console
@media (max-width: 767px) {
  .mat-mdc-raised-button {
    min-width: inherit !important;
  }
  .thankyou-row {
    margin-top: 2em;
    padding: 0 15px;
  }
}

.grid-option {
  text-align: center;
  padding: 10px;
  &.selected {
    div,
    div:hover {
      background-color: map.get($mat-primary, 100);
      border-color: map.get($mat-primary, 700);
    }
  }
  div {
    padding: 20px;
    border: 2px solid #f2f2f2;
    cursor: pointer;
    &:hover {
      border-color: map.get($mat-primary, 100);
    }
  }
}

app-navbar-cmp {
  z-index: 9;
}

.error-label {
  color: #f44336
}

.smaller-label {
  font-size: smaller
}

.m-10 {
  margin: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.p5 {
  padding: 5px !important;
}

address {
  font-style: normal;
  line-height: inherit;
}

.pointer {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}

.of-auto {
  overflow: auto;
}

#offline-banner {
  .offline-banner-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: map.get($map: mat.$yellow-palette, $key: 500);
    color: rgba(0,0,0, .87);
    z-index: 1000;
    padding: 1rem;
    line-height: 32px;

    &.unreliable {
      background: map.get($map: mat.$red-palette, $key: 300);
      color: white;
    }
  }
}


.liability-estimator, .appointment-schedule {
  width: 100%;
  margin-bottom: 40px;

  .rounded-full {
    border-radius: 9999px;
  }
  .header {
    padding: 20px 0 10px;
  }
  .footer {
    text-align: center;
    position: relative !important;
  }
  .main-section {
    background-color: var(--primary-background);
    color: white;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 6rem;

    .container {
      display: inline-block;
    }
    .title {
      font-size: 45px;
    }

    .search-box {
      height: 80px;
      margin: 0 auto;
      padding: 0 0.75rem 0 0.75rem;
      border: solid 1px;
      border-radius: 9999px;
      align-items: center;
      max-width: 36rem;
      color: var(--text-color);
      background-color: white;

      &.disabled {
        opacity: 0.8;
        pointer-events: none;
      }

      // fixes appearance of the form field in search box - hides underline, removes fill color, fixes positioning
      .mat-mdc-form-field {
        margin-top: 19px;

        .mat-mdc-text-field-wrapper {
          background-color: transparent;
        }

        .mdc-line-ripple {
          display: none;
        }

        &.mat-focused,
        &:hover {
          .mat-mdc-form-field-focus-overlay {
            opacity: 0;
          }
        }
      }
    }
  }

  .autocomplete {
    width: 100%;
    max-width: 600px;
  }

  .schedule-section {
    width: 100%;
    display: block;
    &:nth-child(odd) { background: transparent }
    &:nth-child(even) { background: white }
    > div {
      padding: 30px 20px 50px;
      min-height: 290px;
    }
    .mat-mdc-card {
      width: 100%;
      max-width: 500px;
      overflow: visible;
    }
    .mat-mdc-card.wide {
      max-width: 700px;
    }
    .form {
      background: white;
    }
  }

  @media screen and (max-width: 800px) {
    .header .title {
      white-space: inherit;
      font-size: 25px;
    }
  }
}

