@use 'scss/variables' as *;

/**
 * Google Maps autocomplete
 */

.pac-item-query {
  font-family: $font-family-base !important;
  font-size: 16px;
}

.pac-item:hover {
  background-color: #dfdfdf !important;
  transition: background .15s linear;
}

.pac-container {
  color: rgb(33,33,33) !important;
  background-color: #fafafa;

  &::after {
    height: 30px;
    padding: 10px;
    background-origin: content-box
  }

  .pac-item {
    font-family: $font-family-base !important;
    font-weight: 300 !important;
    color: rgb(33,33,33) !important;
    line-height: 40px !important;
    background-color: #fff !important;
  }
}

.pac-icon, .pac-icon-marker {
  background: none !important;
  background-image: none !important;
}
