@use 'sass:map';
@use '@angular/material' as mat;

@use '../ppal-colors' as *;
@use '../ppal-variables' as *;

$typography: mat.m2-define-legacy-typography-config(
  $font-family: $font-family-base,
  $headline: mat.m2-define-typography-level(32px, 48px, 700),
  $display-1: mat.m2-define-typography-level(1.875rem, 2.25rem, 800, $font-family-base),
  $display-2: mat.m2-define-typography-level(1.25rem, 1.75rem, 700, $font-family-base),
  $display-3: mat.m2-define-typography-level(1.125rem, 1.75rem, 600, $font-family-base),
  $display-4: mat.m2-define-typography-level(0.875rem, 1.25rem, 600, $font-family-base),
  $subheading-1: mat.m2-define-typography-level(0.875rem, 1.25rem, 400, $font-family-base),
  $subheading-2: mat.m2-define-typography-level(0.875rem, 1.25rem, 500, $font-family-base),
  $body-1: mat.m2-define-typography-level(0.875rem, 1.5rem, 400, $font-family-base),
  $body-2: mat.m2-define-typography-level(0.875rem, 1.5rem, 500, $font-family-base),
  $caption: mat.m2-define-typography-level(75%, 1rem, 400, $font-family-base),
  $button: mat.m2-define-typography-level(0.875rem, 0.875rem, 500, $font-family-base),
);

/**
 * Get a color from colors map
 */
@function ppal-color($color, $hue: 500, $opacity: 1) {
  // Get the color palette
  $palette: map.get($ppal-colors, $color);

  // Make sure the palette is available
  @if ($palette == null) {
    @error "Color '#{$color}' is not available!";
  }

  // Get the color
  $color: map.get($palette, $hue);

  // Make sure the hue is available
  @if ($color == null) {
    @error "Hue '#{$hue}' is not available!";
  }

  // Apply the opacity if possible
  @if (type-of($color) == color) {
    $color: rgba($color, $opacity);
  }

  // Return the color
  @return $color;
}

/**
 * Modify the Angular Material theme object to soften foreground colors
 * on light themes and increase contrast on dark themes
 */
@function _ppal-modify-angular-material-theme-colors($theme) {
  $color: map.get($theme, color);

  // Store the is-dark for convenience
  $is-dark: map.get($color, is-dark);
  $primary: map.get($color, primary);

  $mat-foreground: map.get($color, foreground);
  $mat-background: map.get($color, background);

  // Generate the modified foreground palette based on
  // Angular Material's mat-xxx-theme-foreground map
  $foreground: (
    base: if($is-dark, white, black),
    divider:
    if(
        $is-dark,
        rgba(ppal-color('cool-gray', 100), 0.12),
        ppal-color('cool-gray', 200)
    ),
    dividers:
    if(
        $is-dark,
        rgba(ppal-color('cool-gray', 100), 0.12),
        ppal-color('cool-gray', 200)
    ),
    disabled:
    if($is-dark, ppal-color('cool-gray', 600), ppal-color('cool-gray', 400)),
    disabled-button:
    if($is-dark, ppal-color('cool-gray', 600), ppal-color('cool-gray', 400)),
    disabled-text:
    if($is-dark, ppal-color('cool-gray', 600), ppal-color('cool-gray', 400)),
    elevation: black,
    hint-text:
    if($is-dark, ppal-color('cool-gray', 500), ppal-color('cool-gray', 400)),
    secondary-text:
    if($is-dark, ppal-color('cool-gray', 200), ppal-color('cool-gray', 900)),
    icon:
    if($is-dark, ppal-color('cool-gray', 100), ppal-color('cool-gray', 500)),
    icons:
    if($is-dark, ppal-color('cool-gray', 100), ppal-color('cool-gray', 500)),
    text: if($is-dark, white, ppal-color('cool-gray', 800)),
    slider-min: if($is-dark, white, ppal-color('cool-gray', 800)),
    slider-off:
    if($is-dark, ppal-color('cool-gray', 500), ppal-color('cool-gray', 300)),
    slider-off-active:
    if($is-dark, ppal-color('cool-gray', 400), ppal-color('cool-gray', 400)),
  );

  // Generate the modified background palette based on
  // Angular Material's mat-xxx-theme-background map
  $background: (
    status-bar:
    if($is-dark, ppal-color('cool-gray', 700), ppal-color('cool-gray', 300)),
    app-bar: if($is-dark, ppal-color('cool-gray', 900), white),
    background:
    if($is-dark, ppal-color('cool-gray', 900), ppal-color('cool-gray', 100)),
    hover: if($is-dark, rgba(255, 255, 255, 0.05), map.get($primary, 50)),
    card: if($is-dark, ppal-color('cool-gray', 800), white),
    dialog: if($is-dark, ppal-color('cool-gray', 800), white),
    disabled-button:
    if(
        $is-dark,
        rgba(ppal-color('cool-gray', 900), 0.38),
        rgba(ppal-color('cool-gray', 400), 0.38)
    ),
    raised-button: if($is-dark, ppal-color('cool-gray', 900), white),
    focused-button:
    if($is-dark, ppal-color('cool-gray', 200), ppal-color('cool-gray', 500)),
    selected-button:
    if($is-dark, rgba(255, 255, 255, 0.05), ppal-color('cool-gray', 200)),
    selected-disabled-button:
    if($is-dark, ppal-color('cool-gray', 800), ppal-color('cool-gray', 200)),
    disabled-button-toggle:
    if($is-dark, ppal-color('cool-gray', 900), ppal-color('cool-gray', 300)),
    unselected-chip:
    if($is-dark, ppal-color('cool-gray', 600), ppal-color('cool-gray', 200)),
    disabled-list-option:
    if($is-dark, ppal-color('cool-gray', 200), ppal-color('cool-gray', 300)),
    tooltip:
    if($is-dark, ppal-color('cool-gray', 500), ppal-color('cool-gray', 800)),
  );

  // Store the modified theme.
  //
  // Since modifications only being done on 'foreground'
  // and 'background' palettes, add them from above but
  // keep everything else original
  $background: map-merge($mat-background, $background);
  $foreground: map-merge($mat-background, $foreground);
  $color: map-merge($color, (background: $background, foreground: $foreground));
  $modified-theme: map-merge($theme, (color: $color));

  // Return the modified theme
  @return $modified-theme;
}

/**
 * Generate an Angular Material light theme
 * and modify it before returning
 */
@function ppal-light-theme($primary-palette, $accent-palette, $warn-palette) {
  // Generate the Angular Material theme
  $angular-material-theme: mat.m2-define-light-theme((
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette
    ),
    typography: $typography,
    density: 0
  ));

  // Modify and return the theme
  @return _ppal-modify-angular-material-theme-colors($angular-material-theme);
}

/**
 * Generate an Angular Material dark theme
 * and modify it before returning
 */
@function ppal-dark-theme($primary-palette, $accent-palette, $warn-palette) {
  // Generate the Angular Material theme
  $angular-material-theme: mat.m2-define-dark-theme((
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette
    ),
    typography: $typography,
    density: 0
  ));

  // Modify and return the theme
  @return _ppal-modify-angular-material-theme-colors($angular-material-theme);
}
