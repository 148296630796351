@use 'sass:map';
@use "@angular/material" as mat;

// Mat Button
.mat-mdc-button-base.mat-primary {
  color: #fff;
}

// Mat Chip
.mat-mdc-chip {
  position: relative;
  overflow: hidden;

  &:not(.mat-mdc-basic-chip) {
    display: inline-block;
    padding: 8px 12px 8px 12px;
    border-radius: 24px;
    font-size: 13px;
    line-height: 16px;
  }
}

// Mat Dialog
.custom-dialog-container .mat-mdc-dialog-container {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.0);
}

// Mat Icon
.mat-icon {
  &.green {
    color: map.get(mat.$m2-green-palette, 500) !important;
  }

  &.red {
    color: map.get(mat.$m2-red-palette, 500) !important;
  }

  &.yellow {
    color: map.get(mat.$m2-amber-palette, 500) !important;
  }

  &.flashing {
    animation: blinker 2s linear infinite;
    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
  }
}

// Mat Icon Button
.mat-mdc-icon-button[mat-sm-button] {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.mat-mdc-icon-button[mat-xs-button] {
  height: 20px;
  width: 20px;
  line-height: 20px;
}
.mat-mdc-icon-button[mat-xs-button] .mat-icon {
  font-size: 16px;
  line-height: 20px !important;
  height: 20px;
  width: 20px;
}

.mat-mdc-icon-button.img-button img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

// Mat List
.mat-mdc-list-base .mat-mdc-list-item,
.mat-mdc-menu-item,
.mat-mdc-option,
mat-select-trigger {
  font-size: 0.875rem !important;
}

.mat-mdc-option.full-width-item {
  .mdc-list-item__primary-text {
    flex-grow: 2;
  }
}

// Mat Ripple
.mat-ripple {
  position: relative;
}

// Mat Select
.mat-mdc-select-value-text {
  font-size: 16px !important;
}

// Mat Sidenav
.mat-sidenav-container {
  height: 100%;
}
.mat-sidenav .mat-mdc-list-item:hover {
  background: rgba(0, 0, 0, .035) !important;
}

// Mat Slide Toggle
.mat-mdc-slide-toggle {
  white-space: inherit !important;

}

// Mat Snackbar
.mat-mdc-snack-bar-container {
  color: white;

  &.error, &.warn {
    background-color: #DD4B39;
  }
  &.success {
    background-color: #00b894;
  }

  .mat-mdc-button-base {
    color: #fff;
  }
}

// Mat Tooltip
.mat-mdc-tooltip {
  white-space: pre-line;
}
