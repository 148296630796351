@use '../variables' as *;

.mat-mdc-card {
  overflow: hidden;
  margin: .333333rem 0 0;

  .mat-mdc-card-actions {
    padding: 16px;
  }

  > .mat-mdc-progress-bar {
    margin-bottom: 1rem;
  }

  .mat-mdc-card-footer {
    margin: 0;
  }

  &.disabled {
    .mat-mdc-table {
      opacity: 0.5;
    }
  }

  &.warn {
    border-left: 10px solid var(--warn-color);
  }

  &.accent {
    border-left: 10px solid var(--warn-color);
  }

  &.success {
    border-left: 10px solid var(--primary-color);
  }
}

.mat-mdc-card-title {
  font-size: 20px;
  color: var(--primary-text-color);

  .mat-divider {
    margin-left: -24px;
    margin-right: -24px;
  }
}

// todo remove mat prefix from our custom styles to avoid confusion
.mat-card-round {
  border-radius: 10px !important;
  @media screen and (max-width: 599px) {
    border-radius: 0;
  }
}

.mat-card-top {
  margin-bottom: 0;
  margin-right: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@media (max-width: $media-max-width) {
  .mat-mdc-card-title .mat-divider {
    margin-left: 0;
    margin-right: 0;
  }
}
